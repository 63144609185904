<template>
    <UserAPIstartPage v-if="show"/>
</template>

<script>
import UserAPIstartPage from '../components/API_services/UserAPI_startPage.vue'
import {
    mapActions,
    mapGetters

} from 'vuex'

export default {
    components: {
        UserAPIstartPage,
    },
    data: () => ({
        show: false
    }),
    methods: {
        ...mapActions(['getContactInfo']),
    },
    mounted() {
        this.getContactInfo()
            .then(data => {
                if (!data.isSellToEmployee) {
                    this.show = true
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))

    },
}
</script>
