<template>
<v-container fluid fill-height>
    <v-card :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
        <v-card-title class="px-0 justify-space-between">
            <h1>Інтеграція з API Юг-контракт</h1>
        </v-card-title>
        <v-card-text class="mt-4 px-0 body-1">
            Якщо Ви знаєте що таке API та для чого він потрібен, перейдіть на сторінку з <router-link to="/api-services">технічним описом API</router-link>.
        </v-card-text>
        <v-card-text class="mt-4 px-0 body-1 second--text">
            <v-row>
                <v-col>
                    <h1 class="my-4 px-0 text-center">
                        1. Початок роботи
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" lg="5">
                    <div class="text-center">
                        <v-icon x-large color="primary" class="my-4">fingerprint</v-icon>
                    </div>
                    <h2 class="mb-4 text-center">Отримання доступу до API</h2>
                    <p>Доступ до API мають усі користувачі В2В-порталу Юг-контракт.</p>
                    <p>Перед тим як почати користуватися API потрібно отримати Ключ у розділі <router-link to="/api-settings">Налаштування</router-link>. Також, перевірте у розділі перелік IP-адрес, дозволених для роботи з API. Якщо необхідної адреси немає у переліку, то доступ з неї до API буде неможливий.</p>
                    <p><b class="primary--text">Зверніть увагу!</b> Доступ до окремих API-інтерфейсів визначається рівнем вашого доступу безпосередньо на В2В-порталі. Тобто через сервіс можна отримати тільки ту інформацію, яка доступна на порталі. </p>
                </v-col>
                <v-col cols="12" sm="6" lg="5" offset-lg="2">
                    <div class="text-center">
                        <v-icon x-large color="primary" class="my-4">code</v-icon>
                    </div>
                    <h2 class="mb-4 text-center">Побудова запитів до API</h2>
                    <p>Обмін данними за допомогою API виконується по протоколу HTTPS з використанням засобу передачі данних POST або GET на точку входу, визначену для кожного методу, та обмінюється данними у форматі JSON (за вийнятком Прайс-листів).</p>
                    <p>Перелік всіх інтерфейсів API з описом - <router-link to="/api-services">Інтерфейси API</router-link>.</p>
                    <p>Портал дозволяє тестувати окремі запити безпосередньо на сторінці опису методу.</p>
                    <p>Також для Вашої зручності надаємо приклади реалізації методів на мові Node.js та PHP.</p>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="mt-4 px-0 body-1 second--text">
            <v-row>
                <v-col>
                    <h1 class="my-4 px-0 text-center">
                        2. Прайс-листи та замовлення
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" lg="5">
                    <div class="text-center">
                        <v-icon x-large color="primary" class="my-4">request_quote</v-icon>
                    </div>
                    <h2 class="mb-4 text-center">Прайс-листи</h2>
                    <p>Найважливішим сервісом API можна вважати отримання Прайс-листів. Тому портал надає можливість отримати прайси через API у різних форматах:</p>
                    <ul>
                        <li>JSON</li>
                        <li>XML</li>
                        <li>Excel</li>
                    </ul>
                    <p>Опис методів отримання прайсу - для формату <router-link to="/api-services/get-price">JSON</router-link> або <router-link to="/api-services/get-price-ws">XML, Excel</router-link>.</p>
                </v-col>
                <v-col cols="12" sm="6" lg="5" offset-lg="2">
                    <div class="text-center">
                        <v-icon x-large color="primary" class="my-4">shopping_cart</v-icon>
                    </div>
                    <h2 class="mb-4 text-center">Замовлення</h2>
                    <p>Не менш важливим та затребуваним сервісом API є робота із замовленнями.</p>
                    <p>Для автоматизації створення та резервування замовлення реалізовний метод <router-link to="/api-services/post-order">post-order</router-link>.</p>
                    <p>Подальша обробка замовлення через API також можлива - підтвердження відвантаження замовлення (<router-link to="/api-services/confirm-order">confirm-order</router-link>), отримання оновленної специфікації замовлення (<router-link to="/api-services/get-order-info">get-order-info</router-link>), отримання серійних номерів, які були відвантажені у замовленні (<router-link to="/api-services/get-order-sn">get-order-sn</router-link>) та ін.</p>
               </v-col>
            </v-row>
        </v-card-text>
        <v-card-text class="mt-4 px-0 body-1 second--text">
            <v-row>
                <v-col>
                    <h1 class="my-4 px-0 text-center">
                        3. Контент
                    </h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="8" lg="6" offset-lg="3" offset-sm="2">
                    <div class="text-center">
                        <v-icon x-large color="primary" class="my-4">auto_awesome</v-icon>
                    </div>
                    <h2 class="mb-4 text-center">Видача контенту</h2>
                    <p>Якщо Ви маєте свою інтернет-вітрину, то ми можемо допомогти її наповнити описами товарів, які маємо.</p>
                    <p><router-link to="/api-services/get-content-goods">Опис товарів каталогу</router-link>.</p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

</v-container>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {},
    data: () => ({}),
    props: {},
    methods: {},
    computed: {},
    created() {
    }
}
</script>

<style>
</style>
